import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { Client } from '../client/client.types';
import { Token } from '../cognito/token';

@Injectable({
    providedIn: 'root'
})
export class DossierService implements Resolve<any> {
    routeParams: any;
    client: Client;
    // payments: Payments;
    // program: any;
    onDossierChanged: BehaviorSubject<any>;
    params: any;
    programParams: any;
    private currentTokenSubject: BehaviorSubject<Token>;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param router
     */
    constructor(
        private _httpClient: HttpClient,
        private router: Router
    ) {
        // Set the defaults
        this.onDossierChanged = new BehaviorSubject({});
        this.params = new HttpParams()
            .set('_format', 'json')
            .set('cache', 'false')
            .set('files', 'true');
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getClient()]).then(() => {
                resolve();
            }, reject);
        });
    }

    /**
     * Get client
     *
     * @returns {Promise<any>}
     */
    getClient(): Promise<any> {
        const user = JSON.parse(localStorage.getItem('user'));
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(
                    `${environment.apiUrl}/enutri/client/` +
                        user.clientProfileId,
                    { params: this.params }
                )
                .subscribe({
                    next: (response: any) => {
                        this.client = response;
                        this.onDossierChanged.next(this.client);
                        // console.log('client', response);
                        resolve(this.client);
                    },
                    error: (error) => {
                        console.error(error);
                        reject(error);
                    },
                });
        });
    }
}
